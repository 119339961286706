<template>
  <v-data-table
    class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
    dense
    fixed-header
    :headers="headerData"
    :items="itemsCanceled"
    :loading="loading"
    hide-default-footer
    disable-pagination
    height="100%"
    width="100%"
    item-class="text-center"
    item-key="itemID"
  >
    <template v-slot:[`item.release_date`]="{ item }">
      {{ parseDate(item.release_date) }}
    </template>
    <template v-slot:[`item.release_ref`]="{ item }">
      <div
        class="d-flex align-center"
        :style="{ justifyContent: 'space-evenly' }"
      >
        <span>
          {{ item.release_ref }}
        </span>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              @click="copyRelease(item.release_ref)"
              color="#20212E"
              class="lighten-2 rounded-0 elevation-5"
              icon
              x-small
            >
              <v-icon dark> mdi-content-copy </v-icon>
            </v-btn>
          </template>
          <span>Copy release code, and used it</span>
        </v-tooltip> -->
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import { parseDate } from "@/helpers/mocks/dates";

export default {
  props: ["itemsCanceled"],
  // async created() {
  //   this.loading = true;
  //   let result = await this.getReleasesCanceled();
  //   this.items = result.data;
  //   this.loading = false;
  // },
  computed: {
    headerData() {
      return generateHeaderData(this.header, "min-width");
    },
  },
  data() {
    return {
      loading: false,
      header: [
        { text: "Order No.", value: "doc_ref" },
        { text: "Release", value: "release_ref" },
        { text: "Date", value: "release_date" },
        { text: "Comment", value: "comment" },
        { text: "login", value: "login" },
        { text: "Qty", value: "qty" },
      ],
      items: [],
    };
  },
  methods: {
    ...mapActions({
      getReleasesCanceled: "moduleOrders/moduleDetails/getReleasesCanceled",
    }),
    parseDate,
    copyRelease(releaseCode) {
      this.$emit("releaseCopy", releaseCode);
    },
  },
};
</script>

<style></style>
