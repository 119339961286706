<template>
  <v-row class="darker_blue justify-space-around rounded-0">
    <v-col
      v-for="(stepper, i) in steppers"
      :key="i"
      class="d-flex col-ajust flex-column"
    >
      <v-icon
        :color="stepper ? (stepper === 'R' ? 'warning' : 'green') : 'red'"
      >
        mdi-circle
      </v-icon>
      <span class="text-caption font-weight-bold">{{ i }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { get_cancel_prior_pipeline } from "@/helpers/api/Orders/index.js";
export default {
  watch: {
    vendStepDescr: {
      async handler() {
        if (this.vendStepDescr.startsWith("CANCELED")) {
          let result = await get_cancel_prior_pipeline(this.tabDetails.id);
          this.cancelStepperStatus = result.data[0].step_descr;
          console.log(this.vendStepDescr);
        }
      },
    },
  },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
      infoMessage: (state) => state.moduleOrders.moduleDetails.infoMessage,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
    }),
    vendStepDescr() {
      if (this.tabSelected.details.vendStepDescr)
        return this.tabSelected.details.vendStepDescr.toUpperCase().trim();
      else return null;
    },
    cancelStepperStatusValue() {
      return this.cancelStepperStatus
        ? this.cancelStepperStatus.toUpperCase().trim()
        : "";
    },

    steppers() {
      const steppers = {
        Sent: null,
        "Customer Paid": null,
        // "Release Sent": null,
        "Pending Pickup": null,
        Completed: null,
        "Closed Order": null,
      };
      if (this.tabSelected.details.sent === "R") steppers.Sent = "R";
      if (this.tabSelected.details.sent === "Y") steppers.Sent = true;

      if (!this.vendStepDescr) return steppers;

      if (this.cancelStepperStatusValue) {
        const vendStep = {
          QUOTES: -1,
          NEW: 0,
          "CUSTOMER PAID": 1,
          "PENDING PICK UP": 2,
          "COMPLETED ORDERS": 3,
          "CLOSED ORDERS": 4,
        };

        console.log(this.cancelStepperStatusValue);

        for (let i = 1; i <= vendStep[this.cancelStepperStatusValue]; i++) {
          let key = Object.keys(steppers)[i];

          steppers[key] = this.tabSelected.details.sent === "R" ? "R" : true;
        }
        steppers["Closed Order"] = true;
      } else {
        const vendStep = {
          QUOTE: -1,
          NEW: 0,
          PAID: 1,
          "PENDING PICK UP": 2,
          COMPLETED: 3,
          CLOSED: 4,
        };

        for (let i = 1; i <= vendStep[this.vendStepDescr]; i++) {
          let key = Object.keys(steppers)[i];

          steppers[key] = this.tabSelected.details.sent === "R" ? "R" : true;
        }
      }

      return steppers;
    },
  },
  data() {
    return {
      cancelStepperStatus: null,
    };
  },
  methods: {
    ...mapMutations({
      setStepperIndex: "moduleOrders/moduleDetails/setStepperIndex",
    }),
  },
};
</script>

<style></style>
