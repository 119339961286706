<template>
  <v-container class="pa-0 overflow-y-auto" style="max-height: 20vh">
    <v-data-table
      class="elevation-5 main-table rounded-0 pa-0 ma-0"
      dense
      fixed-header
      :headers="headerData"
      :items="loading ? [] : items"
      :loading="loading"
      hide-default-footer
      disable-pagination
      height="100%"
      width="100%"
      item-key="lineId"
      show-select
      v-model="releasesSelected"
      @click:row="clickRow"
      :item-class="
        (item) => {
          let classes = '';
          classes +=
            selectedLineItems.find((x) => x.itemID === item.itemID) !== undefined
              ? 'primary'
              : '';

          classes += item.rn2_release_ref ? 'cursor-not-allowed' : '';

          return classes;
        }
      "
      @toggle-select-all="selectAllReleases"
    >
      <template v-slot:[`item.data-table-select`]="{ isSelected, select, item }">
        <v-simple-checkbox
          :disabled="!item.rn2_release_ref"
          :value="isSelected"
          @input="select($event)"
          :ripple="false"
          class="text-center"
        ></v-simple-checkbox> </template
      >``
      <template v-slot:[`item.qty`]="{ item, index }">
        {{ Number(item["qty"]) - Number(qtyEOOPicked[index]) }}
      </template>
      <template v-slot:[`item.release_ref`]="{ item }">
        <a
          style="font-weight: 700; font-size: 0.85rem"
          :href="`${url}?invoiceID=${item.doc_ref}&releaseRef=${item.release_ref}&token=${token}`"
          target="_blank"
          @click="dlRemote(item)"
          >{{ item.release_ref }}</a
        >
      </template>
      <template v-slot:[`item.status_sent_to_depot`]="{ item }">
        <v-icon v-if="item.status_sent_to_depot == 'Y'" color="green"
          >mdi-checkbox-marked</v-icon
        ><v-icon v-else color="red">mdi-minus-box</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";
import { trans_log_order } from "@/helpers/api/Orders/index.js";
import { generateHeaderData } from "@/utils/tables.js";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  props: ["header", "selectedLineItems", "releaseDetailsItems", "loading"],
  computed: {
    ...mapState({
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      panelsOpen: (state) => state.moduleOrders.moduleDetails.panelsOpen,
    }),
    ...mapGetters({
      releaseDetailsParse: "moduleOrders/moduleDetails/releaseDetailsParse",
      qtyEOOPicked: "moduleOrders/moduleDetails/qtyEOOPicked",
    }),
    items() {
      this.url = `${process.env.VUE_APP_FILES_URL}/releases/`;
      // this.url = process.env.VUE_APP_SCRIPTCASE_API_PATH;

      this.token = localStorage.getItem("access_token");

      return this.lineItems.map((x, index) => {
        x["lineId"] = `L${index + 1}`;

        if (x.rn2_release_ref) {
          let release = this.releaseDetailsParse.filter(
            (r) => r.doc_item_id === x.itemID
          );

          x = { ...x, ...release[0] };
        }
        return x;
      });
    },
    headerData() {
      return generateHeaderData(this.header, "min-width");
    },
  },
  data() {
    return {
      url: "",
      token: "",
      releasesSelected: [],
      buttonsName: [
        {
          icon: "mdi-rectangle",
          tooltipText: "Container",
          method: "downloadQuote",
        },
        {
          icon: "mdi-account-edit",
          tooltipText: "",
          method: "",
        },
        {
          icon: "mdi-delete",
          tooltipText: "",
          method: "",
        },
      ],
    };
  },
  watch: {
    releasesSelected: {
      deep: true,
      handler() {
        this.$emit("updateReleasesSelected", this.releasesSelected);
      },
    },
  },
  methods: {
    ...mapMutations({
      setItemSelected: "moduleOrders/moduleDetails/setItemSelected",
      setIndexSelected: "moduleOrders/moduleDetails/setIndexSelected",
      setPanelsOpen: "moduleOrders/moduleDetails/setPanelsOpen",
    }),
    selectAllReleases({ items }) {
      if (this.releasesSelected.length) {
        this.releasesSelected = [];
      } else {
        let itemsFiltered = items.filter((x) => x.rn2_release_ref);
        this.releasesSelected = itemsFiltered;
      }
    },
    handlerEventButton(method, item) {
      if (this[method]) this[method](item);
    },
    clickRow(item) {
      if (!item.rn2_release_ref) this.$emit("clickRow", item);
    },
    selectedRow() {
      selectedLineItems.find((x) => {
        x.Location === item.location;
      });
    },
    async dlRemote(item) {
      console.log("dlRemote item", item);

      try {
        trans_log_order({
          order_id: item.doc_id,
          doc_table: "release_note",
          trans_desc: "Release print/view opened",
          application: "rptVendToCustQuote",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      const url = `${process.env.VUE_APP_FILES_URL}/releases/`;
      const token = this.token;
      const baseUrl = `https://nereus-inf-pdf-gen-service-development-vjuqlq6gsq-uw.a.run.app`;

      await remote_upload_pdf_to_order({
        url: `${baseUrl}/?url=${url}?invoiceID=${item.doc_ref}&releaseRef=${item.release_ref}&token=${token}`,
        docType: "SALEINVOICE",
        category: `REL`,
        postCatString: item.depot,
        sourceType: "FILEATTACH",
        sourceid: item.doc_ref,
        permissions: "write,read",
        organization_id: null,
      });
    },
  },
};
</script>

<style lang="scss">
.min-width {
  min-width: none;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>
