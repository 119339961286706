<template>
  <v-row justify="center" align="center">
    <v-col cols="1" v-if="!spinnerShowOD && !pickedUpLineItems[item.itemID] > 0">
      <v-row>
        <v-col class="col-ajust">
          <v-card elevation="5" class="pa-0 fill-height">
            <v-list-item-content
              class="ma-0 pa-0 dark-blue d-flex fill-height text-center"
            >
              <v-list-item-subtitle
                class="ma-0 primary--text font-weight-black"
                tag="span"
              >
                <!-- <v-tooltip bottom> -->
                <!-- <template v-slot:activator="{ on }">
                    <v-btn
                      class="lighten-2 rounded-0 pa-0 ma-auto"
                      x-small
                      icon
                      v-on="on"
                    >
                      <v-icon x-small color="red" dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span> -->
                <!-- </v-tooltip> -->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="11">
      <v-row justify="space-around" class="dark-blue pa-3">
        <v-col
          class="col-ajust"
          v-for="itemsDetail in values"
          :key="itemsDetail.docItemID"
        >
          <v-card elevation="5" class="pa-0 fill-height">
            <v-list-item-content
              class="ma-0 pa-0 dark-blue d-flex fill-height text-center"
            >
              <v-list-item-subtitle
                class="ma-0 primary--text font-weight-black"
                tag="span"
              >
                <template>
                  {{ itemsDetail }}
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: ["item"],
  computed: {
    ...mapState({
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,

      EOO: (state) => state.moduleOrders.moduleDetails.EOO,
      hasEOOLineItems: (state) => state.moduleOrders.moduleDetails.hasEOOLineItems,
      pickedUpLineItems: (state) => state.moduleOrders.moduleDetails.pickedUpLineItems,
      spinnerShowOD: (state) => state.spinnerShowOD,
    }),
    values() {
      let obj = {
        docItemID: `L${
          this.lineItems.findIndex((x) => x.itemID === this.item.itemID) + 1
        }`,
        Location: `${this.item.Location}`,
        sizeDescr: `${this.item.sizeDescr}`,
        typeCode: `${this.item.typeCode}`,
        condDescr: `${this.item.condDescr}`,
        qty: `Qty ${this.item.qty}`,
      };
      return obj;
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style></style>
