<template>
  <v-row class="flex-column">
    <v-col>
      <TableReadOnly :headerTable="headerTable" :items="releaseDetailsState" />
    </v-col>
    <v-col>
      <ReleaseForm
        :items="releaseDetailsState"
        :tabDetailsB="tabDetails"
        @printViewRelease="printViewRelease"
      />
    </v-col>
    <v-row>
      <v-col class="pa-2">
        <v-btn
          class="button elevation-5 pa-2 ma-2"
          small
          color="green"
          outlined
          style=""
          block
          @click="printViewRelease"
        >
          View/Send Release
        </v-btn>
      </v-col>
      <v-col class="pa-2">
        <v-btn
          class="button elevation-5 pa-2 ma-2"
          small
          color="green"
          outlined
          style=""
          block
          @click="printViewRelease"
        >
          Pickup reminder
        </v-btn>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import ReleaseForm from "@/components/OM/Details/ReleaseFormv1.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

import { DateTime } from "luxon";

import { get_release_details } from "@/helpers/api/Orders";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";
import { trans_log_order } from "@/helpers/api/Orders/index.js";

export default {
  components: {
    TableReadOnly,
    ReleaseForm,
  },
  props: ["tabDetailsB"],
  computed: {
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      tabDetails: "moduleOrders/tabDetails",
    }),
    ...mapState({
      releaseDetailsState: (state) =>
        state.moduleOrders.moduleDetails.releaseDetails.map((x) => {
          x.release_date = parseFromIsoToFormat(x.release_date, "yyyy-LL-dd");
          return x;
        }),
    }),
    obj() {
      return {
        ...this.tabDetails,
        vit_vendorTotal: `$ ${parseFloat(
          this.tabDetails.vit_vendorTotal,
        ).toFixed(2)}`,
      };
    },
    headerTable() {
      return generateHeaderData(this.header);
    },
  },
  data() {
    return {
      header: [
        // { text: "id", value: "id" },
        { text: "Release", value: "release_ref" },
        { text: "Date", value: "release_date" },
        { text: "Depot", value: "depot" },
        { text: "Comment", value: "comment" },
        { text: "Qty", value: "qty" },
        { text: "Done", value: "pickup_status" },
      ],
      items: [
        // {
        //   id: "1",
        //   release_ref: "Rel",
        //   release_date: "2022-04-20",
        //   depot: "depot",
        //   qty: "8",
        //   status: "open",
        // },
        // {
        //   id: "2",
        //   release_ref: "Rel",
        //   release_date: "2022-04-21",
        //   depot: "USME",
        //   qty: "5",
        //   status: "open",
        // },
      ],
    };
  },
  async created() {
    const { id, docNo } = await this.tabDetails;
    await this.getReleaseDetails(docNo);

    // const relDetails = await get_release_details(this.docNo);
    // this.items = relDetails.map((x) => {
    //   x.release_date = parseFromIsoToFormat(x.release_date, "yyyy-LL-dd");
    //   return x;
    // });
  },
  methods: {
    ...mapActions({
      getReleaseDetails: "moduleOrders/moduleDetails/getReleaseDetails",
    }),
    async printViewRelease() {
      console.log(this.tabDetails);
      // this.$emit("showIssueRelease", true);
      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;

      window.open(
        `${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        "_blank",
      );

      try {
        trans_log_order({
          order_id: id,
          doc_table: "release_note",
          trans_desc: "Release print/view opened",
          application: "rptVendToCustQuote",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      await remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "REL",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });

      // setTimeout(function () {
      //   window.location.reload(1);
      // }, 3000);
    },
  },
};
</script>

<style></style>
