<template>
  <div>
    <BaseForm class="pa-0 flex-column">
      <template v-slot:fields>
        <v-row>
          <v-col cols="12" class="pa-1">
            <v-autocomplete
              :value="selectedDepot"
              @input="(value) => $emit('setSelectedDepot', value)"
              :items="noUsedDepots"
              :search-input.sync="searchDepot"
              label="Depot"
              color="white"
              outlined
              dense
              background-color="darker_blue"
              hide-details
              append-icon=""
              item-value="Location"
              item-text="Location"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="pa-1 overflow-y-auto" style="max-height: 80px">
            <v-autocomplete
              :value="selectedLineItems"
              @input="addItem"
              :items="lineItemsByDepot"
              :search-input.sync="searchLineItem"
              label="Line Item/s"
              color="white"
              outlined
              dense
              background-color="darker_blue"
              hide-details
              append-icon=""
              multiple
              return-object
              item-value="itemID"
              item-text="lineId"
              :disabled="!(selectedDepot && selectedDepot.length)"
            >
              <template
                v-slot:prepend-item
                v-if="searchLineItem === null || searchLineItem.length === 0"
              >
                <v-list-item @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="flagAll ? 'primary' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template v-slot:selection="{ item }">
                <v-chip color="primary" small close @click:close="$emit('clickRow', item)"
                  >{{ item.lineId }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="pa-1">
            <v-row>
              <v-col cols="7" class="a">
                <v-text-field
                  v-model="obj.release_ref"
                  type="text"
                  outlined
                  dense
                  background-color="darker_blue"
                  color="white"
                  label="Release #"
                  hide-details
                  class="pa-0"
                >
                  <template v-slot:append="">
                    <v-btn
                      class="elevation-5 px-0 py-0 button primary my-0"
                      :style="{ width: '50px' }"
                      outlined
                      block
                      dense
                      :disabled="tabDetails.customerCode === null || !depotIDSelected"
                      @click="generateReleaseHeader"
                    >
                      GEN
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="5" class="">
                <v-col cols="12" class="a">
                  <v-autocomplete
                    v-model="obj.release_ref"
                    :items="itemsCanceled"
                    item-value="release_ref"
                    item-text="release_ref"
                    dense
                    solo
                    label="Reuse release"
                    hide-details
                  >
                  </v-autocomplete>
                </v-col>
              </v-col>
              <p
                class="text-subtitle-2 text-center warning--text ma-1 pa-0"
                v-if="hasCustomerCode"
              >
                <v-icon class="pa-1 ma-0" dark>mdi-information-variant</v-icon>
                {{ hasCustomerCode }}
              </p>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-1">
            <v-text-field
              v-model="obj.comment"
              type="text"
              outlined
              dense
              background-color="darker_blue"
              color="white"
              label="Comment"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-1" v-broker>
            <v-row class="align-center">
              <span class="pa-1 px-2"> Release Org </span>
              <v-checkbox v-model="flagReleaseOrg"> </v-checkbox>
              <v-autocomplete
                :disabled="!flagReleaseOrg"
                v-model="releaseOrg"
                :items="vendorOrg"
                label="Organization"
                color="white"
                outlined
                dense
                background-color="darker_blue"
                hide-details
                append-icon=""
                item-text="nameID"
                item-value="organization_id"
              >
              </v-autocomplete>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:buttons>
        <v-col class="py-2 px-1 text-center">
          <v-btn
            class="elevation-5 px-0 py-0 button primary"
            outlined
            block
            @click="sendData"
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            @click="reset"
            class="elevation-5 px-2 py-0 button button--outlined"
            outlined
            block
          >
            Reset
          </v-btn>
        </v-col>
      </template>
    </BaseForm>
    <v-row col="12" class="pa-2">
      <span v-html="blockedReason" v-if="blocked" style="color: orange"></span>
    </v-row>
  </div>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

import { get_single_order_details_by_docNo_docType_inv } from "@/helpers/api/indexv2";

import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";

export default {
  components: {
    BaseForm,
    DatePicker,
  },
  props: [
    "items",
    "tabDetailsB",
    "selectedLineItems",
    "selectedDepot",
    "canceledReleaseCode",
    "itemsCanceled",
  ],
  data() {
    return {
      docNo: null,
      docId: null,
      depot: null,
      blocked: false,
      blockedReason: null,
      flagReleaseOrg: false,
      releaseOrg: "",
      obj: {
        docNo: null,
        docId: null,
        release_ref: "",
        release_date: "",
        depot: "",
        // Attachment: "",
        // status: "",
        lineItems: [],
        comment: "",
      },
      searchDepot: "",
      searchLineItem: "",
      flagAll: false,
    };
  },
  created() {
    if (!this.list_organizations.length) this.getsOrganizations();
  },
  computed: {
    ...mapState({
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      orgConfig: (state) => state.moduleOrganizations.orgConfig,
      list_organizations: (state) => state.moduleOrganizations.list_organizations,
      list_organizations_vendor: (state) =>
        state.moduleOrganizations.list_organizations_vendor,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      order_bal: ["moduleOrders/moduleDetails/order_bal"],
    }),
    noUsedDepots() {
      return this.lineItemsParse.filter((x) => !x.rn2_release_ref);
    },
    lineItemsParse() {
      return this.lineItems.map((x, index) => {
        x["lineId"] = `L${index + 1}`;
        return x;
      });
    },
    depots() {
      return this.lineItems.map((x) => x.Location);
    },
    depotIDSelected() {
      return this.selectedLineItems.map((x) => x.Location)[0];
    },
    lineItemsByDepot() {
      return this.lineItemsParse.filter(
        (x) => x.Location === this.selectedDepot && !x.rn2_release_ref
      );
    },

    icon() {
      if (this.flagAll) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    customerCode() {
      return this.tabDetails.customerCode;
    },
    hasCustomerCode() {
      // {if (this.tabDetails !== undefined) {
      return this.customerCode === null
        ? "Please, go to the CRM and register a 3 digit code for this customer in order to generate a release number."
        : false;
      // } else {return} ''}
    },
    vendorOrg() {
      return this.list_organizations_vendor.map((x) => {
        return { ...x, nameID: `${x.organization_descr}-${x.organization_id}` };
      });
    },
  },
  watch: {
    canceledReleaseCode: {
      immediate: true,
      handler() {
        if (this.canceledReleaseCode.length) {
          this.obj.release_ref = this.canceledReleaseCode;
        }
      },
    },

    flagReleaseOrg() {
      this.releaseOrg = "";
    },
  },
  methods: {
    ...mapMutations({
      setInfoMessage: "moduleOrders/moduleDetails/setInfoMessage",
    }),
    ...mapActions({
      getOrganizationConfig: "moduleOrganizations/getOrganizationConfig",
      createRelease: "moduleOrders/createRelease",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      getReleaseDetails: "moduleOrders/moduleDetails/getReleaseDetails",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      triggerReleaseHeader: "moduleOrders/triggerReleaseHeader",
      refreshAllPanels: "moduleOrders/moduleDetails/refreshAllPanels",
      getsOrganizations: "moduleOrganizations/getsOrganizations",
    }),
    async initValues() {
      const { docNo, id } = await this.tabDetails;

      this.docNo = docNo;
      this.obj.docNo = docNo;

      this.docId = id;
      this.obj.docId = id;

      const lineItems = await get_single_order_details_by_docNo_docType_inv(docNo);
      this.obj.lineItems = lineItems;

      this.obj.depot = lineItems[0].Location;
      this.depot = lineItems[0].Location;
    },

    reset() {
      for (let key in this.obj) this.obj[key] = "";
      this.$emit("updateSelectedLineItems", []);
      this.$emit("setSelectedDepot", null);
      this.flagReleaseOrg = false;
      this.releaseOrg = "";
      this.blocked = false;
    },
    async sendData() {
      this.blocked = false;
      this.blockedReason = null;

      await this.getOrganizationConfig();

      // const { amount_owed, total } = await this.tabDetails;
      const { total } = await this.tabDetails;

      const amount_owed = this.order_bal;

      if (this.orgConfig.release_option == "ORDER_FULLY_PAID" && amount_owed < total) {
        this.blocked = true;
        this.blockedReason = `
        <span style="color: orange">Unable to create release.</span>
        </br>
        </br>
         <span style="color: white">Rule has been configured to prevent releases from being created until order is paid in full.</span>
        </br>
        </br>

        <span style="color: white">You can turn off this rule from the 'Config' tab in <a href="/settings">Settings</a></span>`;
      } else {
        this.blocked = false;
        this.blockedReason = null;
      }

      if (this.blocked) {
        return false;
      }

      let data = {
        ...this.obj,
        docNo: this.tabDetails.docNo,
        docId: this.tabDetails.id,
        lineItems: this.selectedLineItems,
      };

      this.createRelease({ data, orgID: this.releaseOrg })
        .then((x) => {
          // this.getOrderByDocNo(this.docNo);

          this.reset();
          if (this.tabDetails.sent === "R") {
            this.setInfoMessage({
              panel: null,
              message: ["Please re-issue the Bill Of Sale, line items have changed."],
              disabledPanels: [2],
            });
          } else {
            this.setInfoMessage(null);
          }

          // RE ENABLE AFTER TESTING
          // this.$emit("printViewRelease", true);

          this.updateDetails({ name: "release", data: this.selectedLineItems });
          // this.refreshAllPanels({ docNo: this.docNo, id: this.docId });
          // }, 1000);

          const url = `${process.env.VUE_APP_FILES_URL}/releases/`;
          const token = localStorage.getItem("access_token");
          const baseUrl = `https://nereus-inf-pdf-gen-service-development-vjuqlq6gsq-uw.a.run.app`;

          remote_upload_pdf_to_order({
            url: `${baseUrl}/?url=${url}?invoiceID=${data.docNo}&releaseRef=${data.release_ref}&token=${token}`,
            docType: "SALEINVOICE",
            category: `REL`,
            postCatString: data.release_ref,
            customFn: `${this.tabDetails.nereus_no}-REL-${data.release_ref}`,
            sourceType: "FILEATTACH",
            sourceid: data.docNo,
            permissions: "write,read",
            organization_id: null,
          });
        })
        .catch((err) => {
          console.log("Error creating release", err);
        });
    },
    addItem(value) {
      this.$nextTick(() => {
        this.flagAll = false;
        this.$emit("updateSelectedLineItems", value);
      });
    },
    toggle() {
      this.flagAll = !this.flagAll;
      if (this.flagAll) this.$emit("updateSelectedLineItems", this.lineItemsByDepot);
      else this.$emit("updateSelectedLineItems", []);
    },
    async generateReleaseHeader() {
      let tb = await this.tabDetails;

      if (tb.customerCode === undefined) tb = await this.tabDetails;

      if (tb.customerCode === undefined) {
        alert(
          "Please, go to the CRM and register a 3 digit code for this customer in order to generate a release number."
        );
      } else {
        this.$emit("clearCanceledReleaseCode");
        let response = await this.triggerReleaseHeader({
          customerCode: tb.customerCode,
          docNo: tb.docNo,
          depotIDSelected: this.depotIDSelected,
        });
        this.obj.release_ref = response.data.header;
      }
    },
  },
};
</script>

<style lang="scss">
.a {
  .v-input__slot {
    padding-right: 0px !important;
  }
  .v-input__append-inner {
    margin: auto !important;
  }
}
.margin {
  margin: 0.5rem;
}
</style>
