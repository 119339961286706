<template>
  <BaseForm class="pa-0 flex-column">
    <template v-slot:fields>
      <v-row>
        <v-col
          :cols="col || 12"
          class="pa-1"
          v-for="{ value, label, type, disabled, col } in fields"
          :key="value"
        >
          <DatePicker
            v-if="type === 'datepicker'"
            v-model="obj[value]"
            :data="obj"
            :keyData="value"
            :title="label"
            :existingModel="obj[value]"
            :disabled="disabled"
          ></DatePicker>
          <v-text-field
            v-else
            type="text"
            v-model="obj[value]"
            outlined
            dense
            background-color="darker_blue"
            color="white"
            :label="label"
            hide-details
            :disabled="disabled"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:buttons>
      <v-col class="py-2 px-1 text-center">
        <v-btn
          class="elevation-5 px-0 py-0 button primary"
          outlined
          block
          :disabled="items.length > 0"
          @click="sendData"
        >
          Save
        </v-btn>
      </v-col>
      <v-col class="py-0 px-1 text-center">
        <v-btn
          @click="reset"
          class="elevation-5 px-2 py-0 button button--outlined"
          outlined
          block
          :disabled="items.length > 0"
        >
          Reset
        </v-btn>
      </v-col>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import {
  parseDateFromFormat,
  parseDate,
  parseFromIsoToFormat,
} from "@/helpers/mocks/dates";

import {
  get_single_order_details_by_docNo,
  get_single_order_headers_by_docNo,
  get_single_order_details_by_docNo_docType_inv,
  get_single_order_headers_by_docNo_docType_inv,
} from "@/helpers/api/indexv2";

export default {
  components: {
    BaseForm,
    DatePicker,
  },
  props: ["items", "tabDetailsB"],
  data() {
    return {
      docNo: null,
      docId: null,
      depot: null,
      fields: [
        { label: "Release #", value: "release_ref", type: "text", col: "6" },
        { label: "Date", value: "release_date", type: "datepicker", col: "6" },
        { label: "Depot", value: "depot", disabled: true, col: "6" },
        // {
        //   label: "Attachment [not ready]",
        //   value: "Attachment",
        //   col: "6",
        //   disabled: true,
        // },
        {
          label: "Comment",
          value: "comment",
          col: "12",
          disabled: false,
        },
      ],
      obj: {
        docNo: null,
        docId: null,
        release_ref: "",
        release_date: "",
        depot: null,
        Attachment: "",
        status: "",
        lineItems: [],
        comment: "",
      },
    };
  },
  created() {
    this.initValues();
  },
  computed: {
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
    }),
  },
  methods: {
    ...mapActions({
      createRelease: "moduleOrders/createRelease",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      getReleaseDetails: "moduleOrders/moduleDetails/getReleaseDetails",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      loadDetails: "moduleOrders/moduleDetails/loadDetails",
      refreshAllPanels: "moduleOrders/moduleDetails/refreshAllPanels",
    }),

    async initValues() {
      const { docNo, id } = await this.tabDetails;

      try {
        this.obj.depot =
          this.$store.state.moduleOrders.moduleDetails.lineItems[0].Location;
        this.depot =
          this.$store.state.moduleOrders.moduleDetails.lineItems[0].Location;
      } catch (error) {
        console.log(error);
        //
      }

      this.docNo = docNo;
      this.obj.docNo = docNo;

      this.docId = id;
      this.obj.docId = id;

      const lineItems = await get_single_order_details_by_docNo_docType_inv(
        docNo,
      );
      this.obj.lineItems = lineItems;

      console.log("this.depot", this.depot);
    },

    reset() {
      for (let key in this.obj) this.obj[key] = "";

      this.initValues();
    },
    sendData() {
      let data = {
        ...this.obj,
        payment_date: this.obj.serverDate_release_date
          ? parseDateFromFormat(
              this.obj.serverDate_release_date,
              "yyyy-LL-dd HH-mm-ss",
              "yyyy-LL-dd",
            )
          : this.obj.payment_date,
      };

      this.obj.lineItems = this.obj.lineItems.map((x) => {
        x.oldQty = x.qty;
        return x;
      });

      this.createRelease({ data }).then((x) => {
        // this.getOrderByDocNo(this.docNo);

        this.reset();

        this.$emit("printViewRelease", true);

        // this.loadDetails(this.docNo);
        this.updateDetails({ name: "release" });
        // this.refreshAllPanels({ docNo: this.docNo, id: this.docId });
        // }, 1000);
      });
    },
  },
};
</script>

<style lang="scss"></style>
