var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 overflow-y-auto",staticStyle:{"max-height":"20vh"}},[_c('v-data-table',{staticClass:"elevation-5 main-table rounded-0 pa-0 ma-0",attrs:{"dense":"","fixed-header":"","headers":_vm.headerData,"items":_vm.loading ? [] : _vm.items,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","height":"100%","width":"100%","item-key":"lineId","show-select":"","item-class":(item) => {
        let classes = '';
        classes +=
          _vm.selectedLineItems.find((x) => x.itemID === item.itemID) !== undefined
            ? 'primary'
            : '';

        classes += item.rn2_release_ref ? 'cursor-not-allowed' : '';

        return classes;
      }},on:{"click:row":_vm.clickRow,"toggle-select-all":_vm.selectAllReleases},scopedSlots:_vm._u([{key:`item.data-table-select`,fn:function({ isSelected, select, item }){return [_c('v-simple-checkbox',{staticClass:"text-center",attrs:{"disabled":!item.rn2_release_ref,"value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:`item.qty`,fn:function({ item, index }){return [_vm._v(" "+_vm._s(Number(item["qty"]) - Number(_vm.qtyEOOPicked[index]))+" ")]}},{key:`item.release_ref`,fn:function({ item }){return [_c('a',{staticStyle:{"font-weight":"700","font-size":"0.85rem"},attrs:{"href":`${_vm.url}?invoiceID=${item.doc_ref}&releaseRef=${item.release_ref}&token=${_vm.token}`,"target":"_blank"},on:{"click":function($event){return _vm.dlRemote(item)}}},[_vm._v(_vm._s(item.release_ref))])]}},{key:`item.status_sent_to_depot`,fn:function({ item }){return [(item.status_sent_to_depot == 'Y')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-box")])]}}],null,true),model:{value:(_vm.releasesSelected),callback:function ($$v) {_vm.releasesSelected=$$v},expression:"releasesSelected"}},[_vm._v("`` ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }