<template>
  <v-row class="flex-column">
    <v-col class="py-2">
      <Tab :tab="tab" @changeTab="changeTab" :tabValues="tabValues" classes="py-2">
        <v-col cols="auto" class="ml-auto px-2">
          <v-btn
            small
            dense
            block
            color="primary"
            style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
            @click="sendToCustomer"
            :disabled="!releasesSelected.length"
          >
            <span class="text-capitalize"> send to customer </span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            small
            dense
            block
            color="primary"
            style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
            @click="sendToDepot"
            :disabled="!releasesSelected.length"
          >
            <span class="text-capitalize"> send to depot </span>
          </v-btn>
        </v-col>
      </Tab>

      <ReleaseItems
        v-if="tab === 0"
        class="dark-blue release-table"
        :header="header"
        @clickRow="clickRow"
        @updateReleasesSelected="updateReleasesSelected"
        :selectedDepot="selectedDepot"
        :selectedDepotId="selectedDepotId"
        :selectedLineItems="selectedLineItems"
        :releaseDetailsItems="releaseDetailsState"
        :loading="loading"
      />
      <ReleaseCanceled
        v-else
        class="dark_blue"
        @releaseCopy="releaseCopy"
        :itemsCanceled="releaseCanceled"
      />
    </v-col>

    <v-col v-if="tab === 0">
      <ReleaseForm
        :items="releaseDetailsState"
        :tabDetailsB="tabDetails"
        :selectedLineItems="selectedLineItems"
        :selectedDepot="selectedDepot"
        :selectedDepotId="selectedDepotId"
        :canceledReleaseCode="canceledReleaseCode"
        :itemsCanceled="releaseCanceled"
        @updateSelectedLineItems="updateSelectedLineItems"
        @setSelectedDepot="setSelectedDepot"
        @clickRow="clickRow"
        @clearCanceledReleaseCode="clearCanceledReleaseCode"
      />
    </v-col>
  </v-row>
</template>

<script>
import ReleaseForm from "@/components/OM/Details/ReleaseForm.vue";
import Tab from "@/components/General/Tabs.vue";
import ReleaseItems from "@/components/OM/Details/ReleaseItems";
import ItemsTable from "@/components/OM/Details/ItemsTable";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";
import { trans_log_order } from "@/helpers/api/Orders/index.js";
import ReleaseCanceled from "@/components/OM/Details/ReleaseCanceled.vue";
export default {
  components: {
    ReleaseItems,
    ReleaseForm,
    ItemsTable,
    Tab,
    ReleaseCanceled,
  },
  props: ["tabDetailsB"],
  computed: {
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      tabDetails: "moduleOrders/tabDetails",
    }),
    ...mapState({
      releaseDetailsState: (state) =>
        state.moduleOrders.moduleDetails.releaseDetails.map((x) => {
          x.release_date = parseFromIsoToFormat(x.release_date, "yyyy-LL-dd");
          return x;
        }),
      releaseCanceled: (state) => state.moduleOrders.moduleDetails.releaseCanceled,
      allDepots: (state) => state.moduleOrders.allDepots,
    }),
    obj() {
      return {
        ...this.tabDetails,
        vit_vendorTotal: `$ ${parseFloat(this.tabDetails.vit_vendorTotal).toFixed(2)}`,
      };
    },
    headerTable() {
      return generateHeaderData(this.header);
    },
  },
  data() {
    return {
      header: [
        { text: "Order No.", value: "doc_ref" },
        { text: "Line", value: "lineId" },
        { text: "Depot", value: "Location" },
        { text: "Release", value: "release_ref" },
        { text: "Date", value: "release_date" },
        { text: "Comment", value: "comment" },
        { text: "Qty", value: "qty" },
        { text: "Status Invoice", value: "pickup_status" },
        { text: "Status Sent to Depot", value: "status_sent_to_depot" },
      ],
      items: [],
      selectedLineItems: [],
      selectedDepot: null,
      selectedDepotId: null,
      selectedReleaseRef: null,
      tab: 0,
      tabValues: [
        {
          title: "Activated",
        },

        {
          title: "Canceled",
        },
      ],
      canceledReleaseCode: "",
      itemsCanceled: null,
      releasesSelected: [],
      loading: false,
    };
  },
  async created() {
    // const { id, docNo } = await this.tabDetails;
    this.loading = true;
    await Promise.all([this.getReleasesCanceled(), this.getDepotsAll()]);
    this.loading = false;
    // this.itemsCanceled = result.data;
    // await this.getReleaseDetails(docNo);
  },
  methods: {
    ...mapActions({
      getReleaseDetails: "moduleOrders/moduleDetails/getReleaseDetails",
      getReleasesCanceled: "moduleOrders/moduleDetails/getReleasesCanceled",
      sendReleases: "moduleOrders/moduleDetails/sendReleases",
      getDepotsAll: "moduleOrders/getDepotsAll",
    }),
    sendToCustomer() {
      let obj = {};
      let token = localStorage.getItem("access_token");

      let url_new = process.env.VUE_APP_FILES_URL;

      obj = this.releasesSelected.reduce((acc, value) => {
        acc[`${value.rn2_release_ref}`] = {
          id: value.id,
          depotid: value.depotId,
          invoiceID: value.doc_id,
          invoiceNO: value.invoice_no,
          release: value.rn2_release_ref,
          url: `${url_new}/releases?header=false&invoiceID=${value.doc_ref}&releaseRef=${value.release_ref}&token=${token}`,
          depotEmail: this.tabDetails.custEmails,
          sendToCustomer: true,
        };
        return acc;
      }, {});
      let arr = Object.values(obj);

      this.sendReleases(arr);
    },
    sendToDepot() {
      let obj = {};
      let token = localStorage.getItem("access_token");
      // let url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      let url_new = process.env.VUE_APP_FILES_URL;

      obj = this.releasesSelected.reduce((acc, value) => {
        let depotEmail = this.allDepots.filter((x) => x.id === value.depotId);
        acc[`${value.depotId}${value.rn2_release_ref}`] = {
          id: value.id,
          depotid: value.depotId,
          invoiceID: value.doc_id,
          invoiceNO: value.invoice_no,
          release: value.rn2_release_ref,
          url: `${url_new}/releases?header=false&invoiceID=${value.doc_ref}&releaseRef=${value.release_ref}&token=${token}`,
          depotEmail: depotEmail[0].email,
        };
        return acc;
      }, {});
      let arr = Object.values(obj);

      this.sendReleases(arr);
    },
    updateReleasesSelected(items) {
      this.releasesSelected = items;
    },

    clickRow(item) {
      if (this.selectedDepot) {
        if (this.selectedDepot === item.Location) {
          let index = this.selectedLineItems.findIndex(
            (element) => element.itemID === item.itemID
          );

          if (index === -1) {
            this.selectedLineItems.push(item);
            this.selectedDepotId = item.depotId;
          } else {
            this.selectedLineItems.splice(index, 1);
            this.selectedDepotId = null;
          }
        } else {
          this.selectedLineItems = [];
          this.selectedLineItems.push(item);

          this.selectedDepotId = item.depotId;
        }
      } else {
        this.selectedLineItems.push(item);
        this.selectedDepotId = item.depotId;
      }

      this.selectedDepot = item.Location;
      this.selectedReleaseRef = item.releae_ref;
    },
    updateSelectedLineItems(item) {
      this.selectedLineItems = item;
    },
    setSelectedDepot(value) {
      if (this.selectedDepot !== value) this.selectedLineItems = [];

      this.selectedDepot = value;
    },
    changeTab(index) {
      this.tab = index;
    },
    releaseCopy(relaseCode) {
      this.changeTab(0);
      this.canceledReleaseCode = relaseCode;
    },
    clearCanceledReleaseCode() {
      this.canceledReleaseCode = "";
    },
  },
};
</script>

<style lang="scss">
.release-table {
  tr {
    cursor: pointer;
  }
}
</style>
